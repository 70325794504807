/* 태블릿용 */
.filter-chips-tablet {
    display: inline-flex;
    margin-top: 1.5em;
    margin-bottom: 0.5em;
    padding-bottom: 10px;
}

.tablet-card-container {
    display: grid;
    grid-template-columns: repeat(4, 25%);
    grid-template-rows: auto;
    justify-content: center;
    align-items: flex-start;
    height: calc(var(--vh, 1vh) * 60);
    overflow-y: scroll;
    width: 100vw;
    background-color: tomato;
}

/* 동영상 카드 한 줄에 3개씩 보이게 하기 */
.tablet-card {
    margin: 12px;
    text-align: center;
    cursor: pointer;
}

.tablet-card img {
    width: 100%;
    border-radius: 10px;
    pointer-events: none;
}

.tablet-video-page #drawer-button{
    position: absolute;
    top: 0;
    right: 40px;
    padding: 6px 8px;
    margin-right: 9px;
    z-index: 2;
    font-size: 1.3em;
    background-color: transparent;
    border: none;
}

.tablet-video-page #tooltip2{
    position: relative;
    top: 0;
    right: 40px;
}