::-webkit-scrollbar {
  display: none;
}
.tabStyle {
  display: inline-block;
  font-size: 1.3em;
  font-weight: bold;
  color: white;
  text-decoration: none;
  margin-top: 10px;
  margin-left: 20px;
  padding: 0 10px;
}

/* 자막 설정 컨트롤러 제목 */
.subtitle-position-controller,
.subtitle-font-size-controller,
.language-selector {
  margin: 1em;
}

ul.language-selector li {
  cursor: pointer;
  width: max-content;
  margin: 0.5em 0;
  height: min-content;

}
/* 자막 설정 컨트롤러*/
/* 자막바 디자인 컨테이너 */
.for-test {
  font-size: 1em;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  list-style-type: none;
  overflow-x: scroll;
  overflow-x: hidden;
  margin: 1em 0;
  font-size: 1em;
}

.for-test li {
  cursor: pointer;
}

.for-test li:nth-child(5),
.for-test li:nth-child(6) {
  margin-left: 1.5em;
}

.for-test .chat-bubble-container {
  margin: 0.8em 0;
}

.intro {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
}

.intro h1 {
  font-weight: bold;
  font-size: 2em;
  margin: 0.5em;
}

.intro span {
  font-size: 1rem;
}

.enter-text {
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  margin: 1em;
  min-width: 5em;
  font-weight: bold;
  background-color: rgb(250, 250, 250, 0.5);
  border-radius: 15px;
  padding: 5px 20px;
}


/* 오류보고창 */
#form-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 6px 8px;
  margin-right: 10px;
}

/* 이건 drawer 버튼 */
.drawer #drawer-button {
  position: absolute;
  top: 40px;
  right: 0;
  padding: 6px 8px;
  margin-right: 9px;
  z-index: 2;
  font-size: 1.3em;
  background-color: transparent;
  border: none;
  cursor: pointer !important;
}

.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: max-content;
  width: max-content;
  position: absolute;
  padding: 20px;
  z-index: 2;
}

#toast {
  visibility: hidden;
  position: fixed;
  z-index: 1;
  top: 10px;
  width: 100%;
  min-width: 100px;
  max-width: 300px;
  padding: 5px 1em;
  align-items: center;
  justify-content: center;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 10px;
  background-color: snow;
  color: black;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}

#toast.show {
  visibility: visible;
  animation: fadein 0.2s, fadeout 0.2s 1.5s;
  text-align: center;
}

@keyframes fadein {
  from {
    top: 0;
    opacity: 0;
  }

  to {
    top: 10px;
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    top: 10px;
    opacity: 1;
  }

  to {
    top: 10px;
    opacity: 0;
  }
}

.guide-tooltip {
  position: fixed;
  top: 40px;
  right: 0;
  z-index: 100;
  display: flex;
}

#tooltip1 {
  position: relative;
  top: 0;
  right: 0;
}

#tooltip2 {
  position: relative;
  top: 40px;
  right: 0;
}

.guide-tooltip img {
  position: relative;
  width: 100%;
  height: auto;
}

.guide-tooltip span {
  position: absolute;
  top: 10px;
  left: 0;
  width: 80%;
  font-size: 16px;
  font-weight: bold;
  padding: 16px;
  z-index: 1;
}

.guide-tooltip .tooltip-button {
  position: absolute;
  bottom: 0;
  right: 0;
  background: tomato;
  color: white;
  padding: 2px 10px;
  margin: 10px 10px;
  border-radius: 10px;
  cursor: pointer;
  border: none;
  height: auto;
  width: fit-content;
  font-size: 14px;
  font-weight: bold;
}