@font-face {
  font-family: 'Pretendard-Regular';
  src: url('https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  unicode-range: U+1100-11FF, U+3130-318F, U+AC00-D7AF, U+3200-32FF, U+FF00-FFEF;
}

@font-face {
  font-family: 'Yu Gothic UI';
  src: local('Yu Gothic UI');
  font-weight: 400;
  font-style: normal;
  unicode-range: U+3040-309F, U+30A0-30FF, U+31F0-31FF, U+4E00-9FFF, U+FF00-FFEF;
}


body {
  font-family: 'Roboto', 'Yu Gothic UI', 'Pretendard-Regular', sans-serif;
}