/* 데스크탑 화면에서 사용되는 CSS 파일입니다. */
body {
    height: 100dvh;
    width: 100dvw;
    margin: 0;
    transition: background-color 0.5s ease-in-out;
    background-image: url('/public/img/bg.avif');
    background-size: auto;
    user-select: none;
    overflow: hidden;
    position: fixed;
}

/* 타이틀 */
.title {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 10px;
    font-size: 4em;
    color: white;
    transition: 0.2s;
    width: 100%;
}

/* 언어 선택 컨테이너 */
.language-setting {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* 필터링 칩 컨테이너 */
.filter-chips-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* 필터링 칩 */
.filter-chips {
    display: inline-flex;
    padding: 1em 0;
}

.card-container-container {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: calc((100vh - 300px));
    max-height: 640px;
    margin-top: 1em;
    overflow-y: scroll;
}

/* 동영상 카드 컨테이너 */
.desktop-card-container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: flex-start;
    width: 1040px;
    height: 100%;
}

/* 동영상 카드 한 줄에 3개씩 보이게 하기 */
.desktop-card {
    position: relative;
    flex: 1 1 1;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 150px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    margin: 5px;
    text-align: center;
    cursor: pointer;
}

.desktop-card img {
    width: 250px;
    height: 150px;
    border-radius: 10px;
    pointer-events: none;
}

/* 동영상 페이지 */

/* 동영상 컨테이너 */
.desktop-video-container {
    position: relative;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
    height: 100dvh;
    z-index: 0;
    background-color: black;
}

.youtube-player {
    position: relative;
    height: auto;
    display: block;
}

/* 자막 컨테이너 */
.subtitle-container {
    position: fixed;
    left: 50%;
    bottom: 5%;
    transform: translate(-50%, -5%);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-self: baseline;
    height: 10%;
    max-height: max-content;
    width: max-content;
    color: white;
    z-index: 3;
    text-align: left;
}

.chat-bubble-container,
.chat-bubble-container-default {
    padding: 2px 10px;
    color: snow;
}

/* 모달 */
.modal-screen {
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(15px);
    justify-content: center;
    align-items: center;
    position: fixed;
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url('/public/img/bg.avif');
    z-index: 10;
}

.modal-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: min-content;
    height: fit-content;
    border-radius: 2em;
    color: snow;
}

.modal-container h1 {
    margin: 0.6em;
    width: max-content;
    font-size: 2em;
}

.type-container {
    background-color: rgb(97, 97, 97, 0.5);
    padding: 1.5em 0 1.5em 1em;
    list-style-type: none;
    justify-content: center;
    border-radius: 20px;
    display: flex;
    flex-wrap: wrap;
    min-width: 650px;
    max-width: 750px;
}

/* 이미지 드래그 금지 */
.type-container img {
    -webkit-user-drag: none;
}

.type-container .chat-bubble-container {
    border-radius: 1.1em;
    font-size: 1em;
    margin: 0.3em 0.3em;
}

.type-button {
    border-radius: 1.1em;
    cursor: pointer;
    margin: 0.1em;
}

.type-button.selected {
    filter: drop-shadow(0 0 0.75em rgba(255, 255, 255, 0.6));
    transition: box-shadow 0.2s, background-color 0.2s;
    border-radius: 2em;
    cursor: pointer;
}

.default-type-button.selected {
    background-color: rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 1em 0.4em rgba(255, 255, 255, 0.2);
    transition: box-shadow 0.2s, background-color 0.2s;
    cursor: pointer;
}

.type-container li:nth-child(4),
.type-container li:nth-child(7) {
    margin-left: 1.9em;
}

.type-container li:nth-child(3) {
    margin-left: -0.8em;
}

.type-container li:last-child {
    margin: 0.6em 0.6em 0 0.6em;
    position: relative;
}

.drawer{
    position: fixed;
    top: 0;
    right: 0;
}


